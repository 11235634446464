
import React, { ReactNode } from 'react'

type BaseViewPortType = {
    children: ReactNode;
}

export default function BaseViewPort({ children }: BaseViewPortType) {
    return (
        <div className='flex flex-col w-full max-w-screen-xl px-4 sm:px-8 md:px-16'>
            {children}
        </div>
    )
}
