import { ReactNode } from "react";

type TableUnterrichtType = {
    lessons: {
        block1stunde1: string;
        block1stunde2: string;
        block1stunde3: string;
        block1stunde4: string;
        block1stunde5: string;
        block1stunde6: string;
        block1stunde7: string;
        block1stunde8: string;
        block2stunde1: string;
        block2stunde2: string;
        block2stunde3: string;
        block2stunde4: string;
        block2stunde5: string;
        block2stunde6: string;
        block2stunde7: string;
        block2stunde8: string;
        block1stunde5alternativ: string;
        block1stunde6alternativ: string;
        block1stunde7alternativ: string;
        block1stunde8alternativ: string;
    }
}

export default function TableUnterricht({ lessons }: TableUnterrichtType) {
    return (
        <table className="scale-75 sm:scale-100 max-w-3xl w-full self-center -mt-24 sm:mt-0">
            <TableRow
                el1={
                <td className="w-1/5 bg-cyan text-2xl font-bold text-base-100 py-4 px-4">
                    Team I/II
                </td>
                }
                el4={
                <td className="w-1/5 bg-cyan/30 text-2xl font-bold text-base-200/80 py-4 px-4">
                    Team III
                </td>
                }
            />
            <TableRow
                el1={
                <td className="w-1/5 bg-cyan text-lg font-bold text-base-100 py-6 px-4 leading-5">
                    1.Block <br />
                    <span className="text-sm font-normal">1. / 2. Stunde</span>
                </td>
                }
                el2={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde1}</p>
                        <p>{lessons?.block1stunde2}</p>
                    </td>
                }
                el4={
                <td className="w-1/5 bg-cyan/30 text-lg font-bold text-base-200/80 py-6 px-4 leading-5">
                    1.Block <br />
                    <span className="text-sm font-normal">1. / 2. Stunde</span>
                </td>
                }
                el5={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block2stunde1}</p>
                        <p>{lessons?.block2stunde2}</p>
                    </td>
                }
            />
            <TableRow
                el1={
                    <td className="w-1/5 relative h-20 flex items-center">
                        <div className="absolute flex pl-8 text-lg font-bold items-center">
                        Frühstückspause{" "}
                        <span className="text-sm font-normal pl-4 pt-1 w-24">
                            (35 Minuten)
                        </span>
                        </div>
                    </td>
                }
            />
            <TableRow
                el1={
                <td className="w-1/5 bg-cyan text-lg font-bold text-base-100 py-6 px-4 leading-5">
                    2.Block <br />
                    <span className="text-sm font-normal">3. / 4. Stunde</span>
                </td>
                }
                el2={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde3}</p>
                        <p>{lessons?.block1stunde4}</p>
                    </td>
                }
                el4={
                    <td className="w-1/5 bg-cyan/30 text-lg font-bold text-base-200/80 py-6 px-4 leading-5">
                        2.Block <br />
                        <span className="text-sm font-normal">3. / 4. Stunde</span>
                    </td>
                }
                el5={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block2stunde3}</p>
                        <p>{lessons?.block2stunde4}</p>
                    </td>
                }
            />
            <TableRow
                el1={
                    <td className="w-1/5 relative h-20 flex items-center">
                        <div className="absolute flex pl-8 text-lg font-bold items-center">
                        Mittagspause{" "}
                        <span className="hidden sm:block text-sm font-normal pl-4 pt-1 w-24">
                            (65 Minuten)
                        </span>
                        </div>
                    </td>
                }
                el3={<td className="font-bold"><p className="hidden sm:block">oder</p></td>}
                el4={
                    <td className="w-1/5 bg-cyan/30 text-lg font-bold text-base-200/80 py-6 px-4 leading-5">
                        <span className="text-sm font-normal">5. Stunde</span>
                    </td>
                }
                el5={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block2stunde5}</p>
                    </td>
                }
            />
            <TableRow
                el1={
                    <td className="w-1/5 bg-cyan text-lg font-bold text-base-100 py-6 px-4 leading-5">
                        <span className="text-sm font-normal">5. Stunde</span>
                    </td>
                }
                el2={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde5}</p>
                    </td>
                }
                el3={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde5alternativ}</p>
                    </td>
                }
                el4={
                    <td className="w-1/5 relative h-20 flex items-center">
                        <div className="absolute flex pl-8 text-lg font-bold items-center">
                        Mittagspause{" "}
                        <span className="hidden sm:block  text-sm font-normal pl-4 pt-1 w-24">
                            (65 Minuten)
                        </span>
                        </div>
                    </td>
                }
            />
            <TableRow
                el1={
                    <td className="w-1/5 bg-cyan text-lg font-bold text-base-100 py-6 px-4 leading-5">
                        3.Block <br />
                        <span className="text-sm font-normal">6. / 7. Stunde</span>
                    </td>
                }
                el2={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde6}</p>
                        <p>{lessons?.block1stunde7}</p>
                    </td>
                }
                el3={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde6alternativ}</p>
                        <p>{lessons?.block1stunde7alternativ}</p>
                    </td>
                }
                el4={
                    <td className="w-1/5 bg-cyan/30 text-lg font-bold text-base-200/80 py-6 px-4 leading-5">
                        3.Block <br />
                        <span className="text-sm font-normal">6. / 7. Stunde</span>
                    </td>
                }
                el5={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block2stunde6}</p>
                        <p>{lessons?.block2stunde7}</p>
                    </td>
                }
            />
            <TableRow
                last
                el1={
                    <td className="w-1/5 bg-cyan text-lg font-bold text-base-100 py-6 px-4 leading-5">
                        <span className="text-sm font-normal">8. Stunde</span>
                    </td>
                }
                el2={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde8}</p>
                    </td>
                }
                el3={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde8alternativ}</p>
                    </td>
                }
                el4={
                    <td className="w-1/5 bg-cyan/30 text-lg font-bold text-base-200/80 py-6 px-4 leading-5">
                        <span className="text-sm font-normal">8. Stunde</span>
                    </td>
                }
                el5={
                    <td className="w-1/5 px-6 leading-5">
                        <p>{lessons?.block1stunde8alternativ}</p>
                    </td>
                }
            />
        </table>
    );
}

type TableRowType = {
  el1?: ReactNode;
  el2?: ReactNode;
  el3?: ReactNode;
  el4?: ReactNode;
  el5?: ReactNode;
  last?: boolean;
};

function TableRow(props: TableRowType) {
  return (
    <tr className={`w-full ${!props.last && 'border-b  border-cyan'}`}>
      {props.el1 ? props.el1 : <td className="w-1/5" />}
      {props.el2 ? props.el2 : <td className="w-1/5" />}
      {props.el3 ? props.el3 : <td className="w-1/5" />}
      {props.el4 ? props.el4 : <td className="w-1/5" />}
      {props.el5 ? props.el5 : <td className="w-1/5" />}
    </tr>
  );
}
