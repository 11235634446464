import { useState } from "react";
import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../components/layout/BaseTextSection";
import BaseAccordion from "../../components/layout/BaseAccordion";
import BaseLinkBox from "../../components/layout/BaseLinkBox";
import { API } from "../../utils/constants";
import BaseViewPort from "../../components/layout/BaseViewPort";
import { fetchData } from "./api/request";
import { useQuery } from "react-query";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import BaseLayout from "../../components/layout/BaseLayout";
import { handleDownload } from "../../services/download";

export default function KigaPage() {
  const [acc1, setAcc1] = useState(false);
  const { data, error, isLoading } = useQuery("dataKigaPage", fetchData);

  return (
    <BaseLayout
      title={data?.seo?.metaTitle ?? ""}
      description={data?.seo?.metaDescription ?? ""}
      keywords={data?.seo?.keywords ?? ""}
      url={data?.seo?.canonicalURL ?? null}
    >
      <BasePage
        color="bg-purple"
        logo="/png/footer/Logo_Kindergarten.png"
        icon="/png/footer/Element Tiere lila.png"
      >
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Kindergarten"}
              sectioncolor={"bg-purple"}
              icons="png/weiss/Elemente_Tiere Kindergarten.png"
              padding="-bottom-[1.3rem]"
            />
            <BaseViewPort>
              <BaseTextSection content={data?.absatz1} />
              <p className="py-2 px-8 max-w-screen-md self-center text-center bg-purple text-base-100 relative mt-6">
                <img
                  src="png/single/Elemente_Hase lila.png"
                  alt="single hase"
                  className="h-20 absolute right-8 -top-16"
                />
                {data?.infobalken1}
              </p>
            </BaseViewPort>
            <div
              style={{
                backgroundImage: `url(${
                  API.img + data?.picture?.data?.attributes?.url
                })`,
              }}
              className="bg-cover bg-center w-full h-[32rem] sm:h-[40rem] md:h-[48rem] mt-16"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color="text-purple"
                content={data?.absatz2}
                padding="pt-8 sm:pt-16"
              />
              <div className="flex w-full justify-end pt-2 pb-8 sm:pb-16">
                <button
                  onClick={() =>
                    handleDownload(
                      API.img + data?.Konzept?.data?.attributes?.url,
                      data?.Konzept?.data?.attributes?.name ??
                        "nasch.pdf"
                    )
                  }
                  className="bg-purple text-sm text-base-100 px-4 py-2"
                >
                  Konzept des Kindergartens
                </button>
              </div>
              <div id="anmeldung" />
              <BaseAccordion
                color="purple"
                bool={acc1}
                func={() => setAcc1(!acc1)}
                headline={data?.dropdown1_headline}
                text={data?.dropdown1_text}
                columns
              />
              <div className="self-center sm:self-end pt-8 sm:pt-16">
                <BaseLinkBox
                  headline="Elternrat"
                  textColor="text-base-100"
                  color="bg-orange"
                  link="/elternrat#_"
                  text="Infos und News vom Elternrat des Kindergartens."
                />
              </div>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
