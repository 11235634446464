import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../components/layout/BaseTextSection";
import LinkElements from "./components/LinkElements";
import BaseSliderImg from "../../components/layout/BaseSliderImg";
import { API } from "../../utils/constants";
import BaseViewPort from "../../components/layout/BaseViewPort";
import ReactMarkdown from "react-markdown";
import FleckRightImg from "../../components/common/FleckRightImg";
import AnimalLineImg from "../../components/common/AnimalLineImg";
import TableUnterricht from "./components/TableUnterricht";
import TableGTA from "./components/TableGTA";
import DownloadArchiv from "./components/DownloadArchiv";
import { handleDownload } from "../../services/download";
import { fetchData, fetchLessons, fetchSchoolArchiv } from "./api/request";
import { useQuery } from "react-query";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import { customComponents } from "../../components/common/markdown/ComponentStyle";
import BaseLayout from "../../components/layout/BaseLayout";
import { HashLink } from "react-router-hash-link";
import gfm from 'remark-gfm';
import '../../style/markdown.css'

export default function SchoolPage() {
  const { data, error, isLoading } = useQuery("dataSchool", fetchData);
  const {
    data: archiv,
    error: errArchiv,
    isLoading: loadingArchiv,
  } = useQuery("dataSchoolArchivGTA", fetchSchoolArchiv);
  const {
    data: lessons,
    error: errLessons,
    isLoading: loadingLesson,
  } = useQuery("dataSchoolLessons", fetchLessons);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-cyan" icon="/png/footer/Element Tiere türkis.png" logo="/png/footer/Logo_Schule.png">
        {(isLoading || loadingArchiv || loadingLesson) && <Loading />}
        {(error || errArchiv || errLessons) && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Schule"}
              sectioncolor={"bg-cyan"}
              icons="png/weiss/Elemente_Tiere Schule.png"
              padding="-bottom-5"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color="text-cyan"
                padding="pt-16"
                content=""
              />
              <TableUnterricht lessons={lessons} />
              <div className="flex flex-col sm:flex-row gap-2 w-full items-center justify-center">
                <HashLink to={data?.button?.link ?? '/'} className="px-4 py-2 bg-cyan text-sm text-base-100 mb-4 w-fit self-center mt-8">{data?.button?.aufschrift}</HashLink>
                <button 
                onClick={() =>
                  handleDownload(
                    API.img + data?.lehrer_liste?.data?.attributes?.url,
                    data?.lehrer_liste?.data?.attributes?.name ?? "nasch.pdf"
                  )
                }
                className="px-4 py-2 bg-cyan text-sm text-base-100 mb-4 w-fit self-center mt-8">Kontakte der Lehrkräfte</button>
              </div>
            </BaseViewPort>
            <FleckRightImg paddingTop={"-top-72"} />
            <BaseViewPort>
              <div id="anmeldung" />
              <BaseTextSection
                headline={data?.headline2}
                color="text-cyan"
                padding="sm:pt-32"
                content=""
              />
              <div className="flex flex-col sm:flex-row gap-8 pb-16 w-full">
              {/* <div className="columns-1 sm:columns-2 gap-8 pb-16"> */}
                <div className="flex flex-col w-full sm:w-1/2">
                  <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.absatz1}</ReactMarkdown>
                  <button
                    onClick={() =>
                      handleDownload(
                        API.img + data?.anmeldebogen?.data?.attributes?.url,
                        data?.anmeldebogen?.data?.attributes?.name ?? "nasch.pdf"
                      )
                    }
                    className="px-4 py-2 bg-cyan text-sm text-base-100 mt-2 mb-4 w-fit"
                  >
                    Anmeldebogen
                  </button>
                  <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.absatz1_1}</ReactMarkdown>
                </div>
                <div className="flex flex-col w-full sm:w-1/2">
                  <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.absatz2}</ReactMarkdown>
                </div>
              </div>
            </BaseViewPort>
            <AnimalLineImg right />
            <BaseViewPort>
              <BaseTextSection
                headline_center
                headline={data?.headline3}
                color="text-cyan"
                padding="sm:pt-16"
                content=""
              />
              <TableGTA />
              <BaseTextSection
                headline_center
                headline={"Archiv"}
                color="text-cyan"
                padding="pt-16"
                content=""
              />
              <DownloadArchiv button={archiv?.archiv_gta} />
              <div className="h-16 max-w-[30rem] self-center mt-8 flex items-center">
                <img src="/png/Logo-sachsen-GTA.png" alt="Logo Sachsen" className="h-full w-fit max-h-[4rem] max-w-[4rem]" />
                <p className="pl-2">{data?.foerderung}</p>
              </div>
              <div className="flex flex-col gap-4 justify-center items-center pt-8">
                <p className="text-center">Konzeption: <br/><ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.Konzept}</ReactMarkdown></p>
                <p className="text-center">Kooperationsvereinbarung zwischen Grundschule und Hort: <br/><ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.Kooperationsvereinbarung}</ReactMarkdown></p>
              </div>
            </BaseViewPort>
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline5}
                color="text-cyan"
                content={data?.absatz3}
              />
            </BaseViewPort>
            <BaseSliderImg imgs={data?.slider1?.data} />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline6}
                color="text-cyan"
                content={data?.absatz4}
              />
              <p className="py-2 w-full max-w-screen-md self-center text-center bg-cyan text-base-100 relative mt-6">
                <img
                  src="png/single/Elemente_Katze blau.png"
                  className="h-24 absolute right-32 -top-[4.75rem]"
                  alt="single cat"
                />
                Unser Bibliothek ist geöffnet (in der Schulzeit): montags bis
                freitags 8.00 Uhr - 14.30 Uhr
              </p>
              <p className="py-2 w-full max-w-screen-md self-center text-center bg-cyan text-base-100 mt-2">
                Kontakt: Telefon: (0341) 487 365 25 und per Mail:
                bibliothek@nasch.de
              </p>
            </BaseViewPort>
            <BaseSliderImg imgs={data?.slider2?.data} />
            <LinkElements />
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
