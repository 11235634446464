import { ReactSVG } from "react-svg";

type FleckLeftImgType = {
    paddingTop: string;
}

export default function FleckLeftImg({ paddingTop }: FleckLeftImgType) {
    return (
        <div className="hidden sm:block w-full relative -z-10">
            <ReactSVG className={`h-80 w-80 absolute left-0 ${paddingTop}`} src='/svg/FleckLeft.svg'/>
        </div>
    )
}
