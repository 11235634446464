
import axios from "axios"
import { API } from "../../../utils/constants"

export const fetchData = async () => {
    const response = await axios.get(API.common + '/schule-page?populate=*')
    return response.data.data.attributes
}

export const fetchSchoolArchiv = async () => {
    const response = await axios.get(API.common + '/schule-page?populate=archiv_gta.pdf')
    return response.data.data.attributes
}

export const fetchLessons = async () => {
    const response = await axios.get(API.common + '/schule-unterrichtszeiten')
    return response.data.data.attributes
}
