import { useQuery } from "react-query";
import { fetchData } from "./api/request";
import BasePage from "../../components/layout/BasePage";
import NotFound from "../../components/common/NotFound";
import Loading from "../../components/common/Loading";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../components/layout/BaseTextSection";
import BaseViewPort from "../../components/layout/BaseViewPort";
import BaseLayout from "../../components/layout/BaseLayout";

export default function Datenschutz() {

  const { data, error, isLoading } = useQuery("dataDatenschutz", fetchData);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-blue" icon="/png/Elemente_Tiere blau.png" logo="/png/footer/Logo_Startseite.png">
      {isLoading && <Loading />}
      {error && <NotFound />}
      {data && (
        <>
          <BaseHeroSmall headline={"Datenschutz"} sectioncolor={"bg-blue"} icons={""} />  
          <BaseViewPort>
          <BaseTextSection content={data?.text} />
          </BaseViewPort>
        </>
      )}
      </BasePage>
    </BaseLayout>
  )
}
