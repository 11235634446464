import BasePage from "../../../components/layout/BasePage";
import BaseHeroSmall from "../../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../../components/layout/BaseTextSection";
import { HashLink } from "react-router-hash-link";
import BaseViewPort from "../../../components/layout/BaseViewPort";
import { API } from "../../../utils/constants";
import AnimalLineImg from "../../../components/common/AnimalLineImg";
import { handleDownload } from "../../../services/download";
import { useQuery } from "react-query";
import { fetchLeitbild } from "../api/request";
import Loading from "../../../components/common/Loading";
import NotFound from "../../../components/common/NotFound";
import BaseLayout from "../../../components/layout/BaseLayout";

export default function LeitbildPage() {
  const { data, error, isLoading } = useQuery("dataLeitbild", fetchLeitbild);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-yellow" icon="/png/footer/Element Tiere gelb.png" logo="/png/footer/Logo_über die NaSch.png">
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline="Über die NaSch"
              sectioncolor={"bg-yellow"}
              icons="/png/weiss/Elemente_Tiere über die NaSch.png"
            />
            <BaseViewPort>
              <BaseTextSection
                color="text-yellow"
                headline={data?.headline1}
                content={data?.absatz1}
              />
            </BaseViewPort>
            <AnimalLineImg />
            <BaseViewPort>
              <div className="border border-yellow py-6 px-4 flex flex-col sm:mt-16">
                <p className="w-full sm:w-4/5 pb-3">{data?.text0}</p>
                <ElementRow
                  button="Hort"
                  color="bg-rose-400"
                  text={data?.text_hort}
                  link="/hort#_"
                />
                <ElementRow
                  button="Kindergarten"
                  color="bg-purple"
                  text={data?.text_kindergarten}
                  link="/kindergarten#_"
                />
                <ElementRow
                  button="Elternrat"
                  color="bg-orange"
                  text={data?.text_elternrat}
                  link="/elternrat#_"
                />
              </div>
              <p className="pt-6 sm:pt-10 sm:pl-2 sm:w-96">{data?.absatz2}</p>

              <button
                className="bg-yellow rounded-none btn-ghost w-fit ml-2 mt-3 px-4 py-2 text-sm"
                onClick={() =>
                  handleDownload(
                    API.img + data?.file?.data?.attributes?.url,
                    data?.file?.data?.attributes?.name ?? "nasch.pdf"
                  )
                }
              >
                Mehr dazu
              </button>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}

type ElementRowType = {
  text: string;
  button: string;
  color: string;
  link: string;
};

function ElementRow({ text, button, color, link }: ElementRowType) {
  return (
    <div className="flex flex-col sm:flex-row py-3">
      <p className="w-full sm:w-4/5">{text}</p>
      <div className="sm:w-1/5 mt-4 sm:mt-0 flex items-center justify-center">
        <HashLink
          className={`w-32 text-center py-3 text-sm text-base-100 ${color}`}
          to={link}
        >
          {button}
        </HashLink>
      </div>
    </div>
  );
}
