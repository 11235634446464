import BasePage from "../../../components/layout/BasePage";
import BaseHeroSmall from "../../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../../components/layout/BaseTextSection";
import BaseViewPort from "../../../components/layout/BaseViewPort";
import BaseSliderImg from "../../../components/layout/BaseSliderImg";
import { fetchTraditionen } from "../api/request";
import { useQuery } from "react-query";
import Loading from "../../../components/common/Loading";
import NotFound from "../../../components/common/NotFound";
import BaseLayout from "../../../components/layout/BaseLayout";

export default function TraditionenPage() {
  const { data, error, isLoading } = useQuery(
    "dataTradition",
    fetchTraditionen
  );

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-yellow" icon="/png/footer/Element Tiere gelb.png" logo="/png/footer/Logo_über die NaSch.png">
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline="über die Nasch"
              sectioncolor={"bg-yellow"}
              icons="/png/weiss/Elemente_Tiere über die NaSch.png"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color={"text-yellow"}
                content={data?.absatz1}
              />
            </BaseViewPort>
            <BaseSliderImg imgs={data?.slider?.data} />
            <BaseViewPort>
              <BaseTextSection content={data?.absatz2} padding="p-0 sm:py-16"/>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
