
interface HistoryElements {
    id: number;
    attributes: {
        headline: string;
        text: string;
    }
}

type BaseHistorySectionType = {
    items: HistoryElements[];
    padding?: boolean;
}

export default function BaseHistorySection({ items, padding } : BaseHistorySectionType ) {
    return (
        <div className={`columns-1 sm:columns-2 md:columns-3 gap-8 w-full ${!padding && 'sm:py-16' }`}>
            {items?.map(item => (
                <HistoryElement key={item.id} item={item}/>
            ))}
        </div>
    )
}

type HistoryElementType = {
    item: HistoryElements
}

function HistoryElement ({ item }  : HistoryElementType) {
    return(
        <div className='flex flex-col h-fit pb-8 w-full'>
            <p>
                <span className='text-base text-slate-900 font-bold'>{item?.attributes?.headline}</span><br/>
                <span className='text-base-200 text-justify'>{item?.attributes?.text}</span>
            </p>
            {/* <h4 className='text-base text-slate-900 font-bold'>{item?.attributes?.headline}</h4>
            <p className='text-base-200 text-justify'>{item?.attributes?.text}</p> */}
        </div>
    )
}
