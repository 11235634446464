import BasePage from "../../../components/layout/BasePage";
import BaseHeroSmall from "../../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../../components/layout/BaseTextSection";
import BaseViewPort from "../../../components/layout/BaseViewPort";
import ReactMarkdown from "react-markdown";
import FleckLeftImg from "../../../components/common/FleckLeftImg";
import AnimalLineImg from "../../../components/common/AnimalLineImg";
import { fetchPositions } from "../api/request";
import { useQuery } from "react-query";
import Loading from "../../../components/common/Loading";
import NotFound from "../../../components/common/NotFound";
import { customComponents } from "../../../components/common/markdown/ComponentStyle";
import BaseLayout from "../../../components/layout/BaseLayout";
import gfm from "remark-gfm";
import "../../../style/markdown.css";
import { useEffect } from "react";

export default function PositionsPage() {
  const { data, error, isLoading } = useQuery("dataPositions", fetchPositions);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <BaseLayout
      title={data?.seo?.metaTitle ?? ""}
      description={data?.seo?.metaDescription ?? ""}
      keywords={data?.seo?.keywords ?? ""}
      url={data?.seo?.canonicalURL ?? null}
    >
      <BasePage
        color="bg-yellow"
        icon="/png/footer/Element Tiere gelb.png"
        logo="/png/footer/Logo_über die NaSch.png"
      >
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Über die NaSch"}
              sectioncolor={"bg-yellow"}
              icons="/png/weiss/Elemente_Tiere über die NaSch.png"
            />
            <BaseViewPort>
              {data?.ausschreibung &&
                data?.ausschreibung?.map(
                  (item: {
                    headline: string;
                    spalteLinks: string;
                    spalteRechts: string;
                  }) => (
                    <Ausschreibung
                      headline={item.headline}
                      spalteLinks={item?.spalteLinks}
                      spalteRechts={item?.spalteRechts}
                    />
                  )
                )}
            </BaseViewPort>
            <FleckLeftImg paddingTop={"-top-80"} />
            <AnimalLineImg right />
            <BaseViewPort>
              {data?.ausschreibung_unten &&
                data?.ausschreibung_unten?.map(
                  (item: {
                    headline: string;
                    spalteLinks: string;
                    spalteRechts: string;
                  }) => (
                    <Ausschreibung
                      headline={item.headline}
                      spalteLinks={item?.spalteLinks}
                      spalteRechts={item?.spalteRechts}
                    />
                  )
                )}
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}

type AusschreibungType = {
  spalteLinks: string;
  spalteRechts: string;
  headline: string;
};

function Ausschreibung({
  spalteLinks,
  spalteRechts,
  headline,
}: AusschreibungType) {
  return (
    <>
      <BaseTextSection
        content=""
        headline={headline}
        color="text-yellow"
        padding="pt-16"
      />
      <div className="flex flex-col sm:flex-row gap-8 ">
        <div className="w-full h-fit">
          <ReactMarkdown
            components={customComponents}
            remarkPlugins={[gfm]}
            className="markdown"
          >
            {spalteLinks}
          </ReactMarkdown>
        </div>
        <div className="w-full h-fit">
          <ReactMarkdown
            components={customComponents}
            remarkPlugins={[gfm]}
            className="markdown"
          >
            {spalteRechts}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
}
