
import React from 'react'
import '../../style/loading.css'

export default function Loading() {
  return (
    <section className='h-full w-full flex items-center justify-center relative grow'>
		<div className="spinner">
			<div></div>   
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
			<div></div>    
		</div>
    </section>
  )
}
