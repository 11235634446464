import { HashLink } from "react-router-hash-link";

type LinkElementBoxType = {
    headline: string;
    textColor: string;
    text?: string;
    color: string;
    link: string;
}

export default function BaseLinkBox ( props : LinkElementBoxType) {
    return(
        <div className={`flex flex-col w-64 min-h-[10rem] p-4 ${props.color} ${props.textColor}`}>
            <h3 className='text-3xl'>{props.headline}</h3>
            <p className="leading-5 pt-4">{props.text}</p>
            <HashLink to={props.link} className='border-2 text-sm border-base-100 px-4 py-2 w-fit mt-8'>Mehr dazu</HashLink>
        </div>
    )
}