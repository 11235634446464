import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseSliderImg from "../../components/layout/BaseSliderImg";
import BaseLinkBox from "../../components/layout/BaseLinkBox";
import BaseViewPort from "../../components/layout/BaseViewPort";
import ReactMarkdown from "react-markdown";
import { API } from "../../utils/constants";
import { handleDownload } from "../../services/download";
import { fetchData } from "./api/request";
import { useQuery } from "react-query";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import { customComponents } from "../../components/common/markdown/ComponentStyle";
import BaseLayout from "../../components/layout/BaseLayout";
import gfm from 'remark-gfm';
import '../../style/markdown.css'

export default function HortPage() {
  const { data, error, isLoading } = useQuery("dataHort", fetchData);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-pink" logo="/png/footer/Logo_Hort.png" icon="/png/footer/Element Tiere rosa.png">
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Hort"}
              sectioncolor={"bg-pink"}
              icons="png/weiss/Elemente_Tiere Hort.png"
              padding="-bottom-[1.59rem]"
            />
            <BaseViewPort>
              <div className="columns-1 sm:columns-2 pt-12 sm:pt-24">
                <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.absatz1}</ReactMarkdown>
                <p className="pt-4">
                  Unser ausführliches Konzept finden Sie hier:
                </p>
                <button
                  onClick={() =>
                    handleDownload(
                      API.img + data?.konzept_ausfuehrlich?.data?.attributes?.url,
                      data?.konzept_ausfuehrlich?.data?.attributes?.name ??
                        "nasch.pdf"
                    )
                  }
                  className="bg-pink text-sm text-base-100 px-4 py-2"
                >
                  Konzept (ausführlich)
                </button>
                <p className="pt-4">
                  Eine komprimierte Version unseres Kinderschutzkonzepts ist hier
                  zu finden:
                </p>
                <button
                  onClick={() =>
                    handleDownload(
                      API.img + data?.konzept_kompakt?.data?.attributes?.url,
                      data?.konzept_kompakt?.data?.attributes?.name ?? "nasch.pdf"
                    )
                  }
                  className="bg-pink text-sm text-base-100 px-4 py-2 mb-4"
                >
                  Konzept (komprimiert)
                </button>
              </div>
            </BaseViewPort>
            <BaseSliderImg imgs={data?.slider?.data} />
            <div className="w-full max-w-screen-xl flex flex-col px-2 gap-2 items-center sm:flex-row">
              <div className="flex flex-col w-full justify-center pr-2 md:pr-0">
                <p className="py-2 w-full max-w-screen-md self-center text-center bg-pink text-base-100 relative">
                  <img
                    src="png/single/Elemente_Elefant lila.png"
                    alt="single elefant"
                    className="h-24 absolute right-8 -top-16"
                  />
                  {data?.infobalken1}
                </p>
                <p className="py-2 w-full max-w-screen-md self-center text-center bg-pink text-base-100 mt-2">
                  {data?.infobalken2}
                </p>
                <p className="py-2 w-full max-w-screen-md self-center text-center bg-pink text-base-100 mt-2">
                  {data?.infobalken3}
                </p>
              </div>
              <BaseLinkBox
                headline={data?.elternrat_box?.headline}
                text={data?.elternrat_box?.beschreibung}
                textColor={"text-base-100"}
                color={"bg-orange"}
                link={data?.elternrat_box?.link}
              />
            </div>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
