
import axios from 'axios'
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react'
import { API } from '../../../utils/constants'

const bspData = [
    { 
        gta: 'Bsp 1',
        klasse: 'Bsp 1',
        wo: 'Bsp 1',
        wann: 'Bsp 1',
    },
    { 
        gta: 'Bsp 1',
        klasse: 'Bsp 1',
        wo: 'Bsp 1',
        wann: 'Bsp 1',
    },
    { 
        gta: 'Bsp 1',
        klasse: 'Bsp 1',
        wo: 'Bsp 1',
        wann: 'Bsp 1',
    },
    { 
        gta: 'Bsp 1',
        klasse: 'Bsp 1',
        wo: 'Bsp 1',
        wann: 'Bsp 1',
    },

]

export default function TableGTA() {

    const [data, setdata] = useState<any>(null)
    useEffect(() => {
        const fetchdata = async () => {
            await axios.get(API.common + '/gtas')
                    .then(res => {
                        console.log(res.data.data)
                        setdata(res?.data?.data)
                    })
                    .catch(err => console.log(err))
        }

        fetchdata()
    }, [])

    return (
        <table className="max-w-2xl w-full self-center mt-8">
            <TableRow 
                el1={
                    <td className="w-3/5 bg-cyan text-xl font-bold text-base-100 py-2 px-4">
                        Angebot
                    </td>
                }
                el2={
                    <td className="w-2/5 bg-base-100/60 text-xl font-bold text-base-200/60 py-2 px-4">
                        für Klasse
                    </td>
                }
            />
            {data && data?.map((item: { attributes: { gta: string | undefined; klasse: string | undefined; wo: string | undefined; wann: string | undefined; } }) => (
                <TableRow 
                    el1={
                        <td className='w-3/5 text-sm px-4 py-2 text-base-200/80'>{item.attributes.gta}</td>
                    }
                    el2={
                        <td className='w-2/5 text-sm px-4 py-2 text-base-200/80 bg-cyan/30'>{item.attributes.klasse}</td>
                    }
                />
            ))} 

        </table>
    )
}

type TableRowType = {
    el1?: ReactNode;
    el2?: ReactNode;
    el3?: ReactNode;
    el4?: ReactNode;
    last?: boolean;
  };
  
  function TableRow(props: TableRowType) {
    return (
      <tr className={`w-full ${!props.last && 'border-b  border-cyan'}`}>
        {props.el1 ? props.el1 : <td className="w-2/5" />}
        {props.el2 ? props.el2 : <td className="w-1/5" />}
        {props.el3 ? props.el3 : <td className="w-1/5" />}
        {props.el4 ? props.el4 : <td className="w-1/5" />}
      </tr>
    );
  }
  