import axios from "axios"
import { API } from "../../../utils/constants"

export const fetchData = async () => {
    const response = await axios.get(API.common + '/blog-page?populate=*')
    return response.data.data.attributes
}

export const fetchBlogData = async () => {
    const response = await axios.get(API.common + '/blogs?populate=*')
    console.log(response.data.data)
    return response.data.data
}

