
import { useMemo } from 'react'
import { HashLink } from 'react-router-hash-link';
import { handleDownload } from '../../../services/download';
import { API } from '../../../utils/constants';

interface CardInterface {
    headline: string;
    text: string;
    link: string;
    file?: {
        data: {
            attributes: {
                name: string;
                url: string;
            }
        }
    }
}

type SignInSectionType = {
    card1: CardInterface;
    card2: CardInterface;
    card3: CardInterface;
}

export default function SignInSection({ card1, card2, card3 }: SignInSectionType) {
    return (
        <div className='flex flex-wrap w-full max-w-screen-xl gap-8 justify-center px-4 md:p-16 h-fit'>
            <SignInElement color='bg-purple' item={card1}/>
            <SignInElement color='bg-cyan' item={card2}/>
            <SignInElement color='bg-cyan' item={card3}/>
        </div>
    )
}

type SignInElementType = {
    item: CardInterface;
    color: string;
}

function SignInElement({ item, color }: SignInElementType ) {

    const link = useMemo(() => item?.link === null ? false : true, [item?.link])

    return(
        <div className={`max-w-[22rem] px-8 py-4 text-base-100 flex flex-col justify-between grow ${color}`}>
            <h3 className='text-xl sm:text-2xl md:text-3xl line-clamp-2 font-medium w-4/5'>{item?.headline}</h3>
            <p className='pb-6 pt-2'>{item?.text}</p>
            { link ? 
                <HashLink to={item.link} className='border-2 border-base-100 btn rounded-none btn-ghost w-fit'>Mehr dazu</HashLink>
                :
                <button onClick={() => handleDownload(API.img + item?.file?.data?.attributes?.url, item?.file?.data?.attributes?.name ?? 'nasch.pdf')} className='border-2 border-base-100 btn rounded-none btn-ghost w-fit'>Mehr dazu</button>
            }
        </div>
    )
}