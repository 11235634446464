import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseViewPort from "../../components/layout/BaseViewPort";
import BaseTextSection from "../../components/layout/BaseTextSection";
import { API } from "../../utils/constants";
import ReactMarkdown from "react-markdown";
import { HashLink } from "react-router-hash-link";
import AnimalLineImg from "../../components/common/AnimalLineImg";
import { handleDownload } from "../../services/download";
import { useQuery } from "react-query";
import { fetchData, fetchKonto } from "./api/request";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import { customComponents } from "../../components/common/markdown/ComponentStyle";
import BaseLayout from "../../components/layout/BaseLayout";
import gfm from 'remark-gfm';
import '../../style/markdown.css'

export default function ClubPage() {
  const { data, error, isLoading } = useQuery("dataVerein", fetchData);
  const {
    data: konto,
    error: errKonto,
    isLoading: loadingKonto,
  } = useQuery("dataVereinKonto", fetchKonto);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-sky" logo="/png/footer/Logo_Verein.png" icon="/png/footer/Elemente Tiere hellblau.png">
        {(isLoading || loadingKonto) && <Loading />}
        {(error || errKonto) && <NotFound />}
        {data && konto && (
          <>
            <BaseHeroSmall
              headline={"Verein"}
              sectioncolor={"bg-sky"}
              icons="png/weiss/Elemente_Tiere Verein.png"
              padding="-bottom-4"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color="text-sky"
                content={data?.absatz1}
              />
            </BaseViewPort>
            <AnimalLineImg />
            {/* TODO -> Dinos */}
            <BaseViewPort>
              <BaseTextSection
                headline="Die Vorstandsarbeit umfasst verschiedene Bereiche:"
                color="text-sky"
                content=""
                padding="pt-4 sm:pt-16"
              />
              <div className="flex flex-col">
                <p className="gap-8 columns-1 sm:columns-2 text-base-200">
                  <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{data?.absatz2}</ReactMarkdown>
                  <div className="flex flex-col gap-2 pt-2">
                    <button
                      onClick={() =>
                        handleDownload(
                          API.img +
                            data?.antrag_mitgliedschaft?.data?.attributes?.url,
                          data?.antrag_mitgliedschaft?.data?.attributes?.name ??
                            "nasch.pdf"
                        )
                      }
                      className="text-sm text-center w-40 py-2 bg-sky text-base-100"
                    >
                      Antrag/Mitgliedschaft
                    </button>
                    <p className="font-bold pt-4">Informationen zu unseren Beiträgen und der Finanzordnung könnt ihr hier nachlesen:</p>
                    <button
                      onClick={() =>
                        handleDownload(
                          API.img + data?.finanzordnung?.data?.attributes?.url,
                          data?.finanzordnung?.data?.attributes?.name ??
                            "nasch.pdf"
                        )
                      }
                      className="text-sm text-center w-40 py-2 bg-sky text-base-100"
                    >
                      Finanzordnung
                    </button>
                    <button
                      onClick={() =>
                        handleDownload(
                          API.img + data?.beitragsordnung?.data?.attributes?.url,
                          data?.beitragsordnung?.data?.attributes?.name ??
                            "nasch.pdf"
                        )
                      }
                      className="text-sm text-center w-40 py-2 bg-sky text-base-100"
                    >
                      Beitragordnung
                    </button>
                    <button
                      onClick={() =>
                        handleDownload(
                          API.img + data?.satzung?.data?.attributes?.url,
                          data?.satzung?.data?.attributes?.name ??
                            "nasch.pdf"
                        )
                      }
                      className="text-sm text-center w-40 py-2 bg-sky text-base-100"
                    >
                      Satzung
                    </button>
                  </div>
                  <div className="flex flex-col pt-8">
                    <p className="font-bold">Vereinskonto:</p>
                    <p>{konto?.title}</p>
                    <p>{konto?.bank}</p>
                    <table>
                      <tr>
                        <td>IBAN</td>
                        <td>{konto?.iban}</td>
                      </tr>
                      <tr>
                        <td>BIC</td>
                        <td>{konto?.bic}</td>
                      </tr>
                      <tr>
                        <td>BLZ</td>
                        <td>{konto?.blz}</td>
                      </tr>
                    </table>
                  </div>
                </p>
                
              </div>
              <HashLink
                to="/hexenhaus#_"
                className="text-sm px-6 py-2 bg-sun text-base-100 w-fit mt-8"
              >
                Zum Hexenhaus
              </HashLink>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
