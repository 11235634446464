import React from "react";

export default function GoogleMaps() {
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1246.305832961975!2d12.332029919625672!3d51.33666216088095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f7177ce73d5f%3A0xc0c7fa7077a054ec!2sNachbarschaftsschule%20Leipzig!5e0!3m2!1sde!2sde!4v1700218656678!5m2!1sde!2sde"
      width="100%"
      height="450"
      allowFullScreen
      loading="lazy"
    ></iframe>
  );
}
