import React, { useMemo } from "react";
import BaseLayout from "../../components/layout/BaseLayout";
import { useQuery } from "react-query";
import { fetchBlogData, fetchData } from "./api/request";
import BasePage from "../../components/layout/BasePage";
import ErrorPage from "../404/ErrorPage";
import { Loading } from "react-daisyui";
import BaseViewPort from "../../components/layout/BaseViewPort";
import ReactMarkdown from "react-markdown";
import { customComponents } from "../../components/common/markdown/ComponentStyle";
import dayjs from "dayjs";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import gfm from 'remark-gfm';
import '../../style/markdown.css'

export default function Blog() {
  const { data, error, isLoading } = useQuery("dataBlogPage", fetchData);
  const {
    data: blogData,
    error: blogError,
    isLoading: BlogIsLoading,
  } = useQuery("dataBlog", fetchBlogData);

  const displayedBlogPosts = useMemo(() => {
    return blogData?.sort(
      (a: any, b: any) =>
        dayjs(b.attributes.createdAt).valueOf() -
        dayjs(a.attributes.createdAt).valueOf()
    );
  }, [blogData]);

  return (
    <BaseLayout
      title={data?.seo?.metaTitle ?? ""}
      description={data?.seo?.metaDescription ?? ""}
      keywords={data?.seo?.keywords ?? ""}
      url={data?.seo?.canonicalURL ?? null}
    >
      <BasePage
        color={"bg-blue"}
        icon="/png/Elemente_Tiere blau.png"
        logo="/png/footer/Logo_Startseite.png"
      >
        <BaseHeroSmall headline={"News & Aktuelles"} sectioncolor={"bg-blue"} />
        {(error || blogError) && <ErrorPage />}
        {(isLoading || BlogIsLoading) && <Loading />}
        {data && blogData && (
          <>
            <BaseViewPort>
              <div className="w-full min-h-[68vh] grow pt-8">
                {displayedBlogPosts?.map((item: any, index: number) => (
                  <BlogPost
                    id={item.id}
                    key={index}
                    headline={item?.attributes?.headline}
                    content={item?.attributes?.content}
                    publish={item?.attributes?.publishedAt}
                  />
                ))}
              </div>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}

export type BlogPostType = {
  id: number;
  headline: string;
  content: any;
  publish: string;
};

function BlogPost({ id, headline, content, publish }: BlogPostType) {
  return (
    <>
      <div id={"post" + id.toString()} />
      <div className="py-4 border-t flex flex-col mt-8 p-4 rounded-lg bg-white shadow-lg">
        <div className="flex flex-row justify-between w-full items-end">
          <h3 className="text-3xl underline leading-3 pl-2 pt-4">{headline}</h3>
          <p className="text-sm text-gray-400">
            {dayjs(publish).format("DD.MM.YYYY")}
          </p>
        </div>
        <p className="px-2">
          <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{content}</ReactMarkdown>
        </p>
      </div>
    </>
  );
}
