import React from 'react'
import BaseLinkBox from '../../../components/layout/BaseLinkBox';

export default function LinkElements() {
    return (
        <div className='flex flex-col sm:flex-wrap sm:flex-row items-center justify-center gap-8'>
            <BaseLinkBox headline='Schulspeisung' textColor='text-base-100' color='bg-cyan' link='https://dussmann-coolcooking.de/de/content/' />
            <BaseLinkBox headline='Schließfächer' textColor='text-base-100' color='bg-cyan' link='https://www.schliessfaecher.de/' />
            
            <div className={`flex flex-col w-64 min-h-[10rem] p-4 bg-yellow text-slate-600`}>
                <h3 className='text-3xl'>Alumni</h3>
                <p className="leading-5 pt-4"></p>
                <a href={'mailto:verein@nasch.de'} className='border-2 text-sm border-base-100 px-4 py-2 w-fit mt-8'>Mehr dazu</a>
            </div>
            <BaseLinkBox headline='Elternrat' textColor='text-base-100' color='bg-orange' link='/elternrat#_' />
        </div>
    )
}