import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import BaseViewPort from "../../components/layout/BaseViewPort";
import BaseTextSection from "../../components/layout/BaseTextSection";
import { API } from "../../utils/constants";
import BaseSliderImg from "../../components/layout/BaseSliderImg";
import { fetchData, fetchImgs } from "./api/request";
import { useQuery } from "react-query";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import BaseLayout from "../../components/layout/BaseLayout";

export default function WitchHousePage() {
  const { data, error, isLoading } = useQuery("dataHexenhaus", fetchData);
  const {
    data: imgs,
    error: errImgs,
    isLoading: loadingImgs,
  } = useQuery("dataHexenhausImgs", fetchImgs);

  return (
    <BaseLayout
      title={data?.seo?.metaTitle ?? ""}
      description={data?.seo?.metaDescription ?? ""}
      keywords={data?.seo?.keywords ?? ""}
      url={data?.seo?.canonicalURL ?? null}
    >
      <BasePage
        color="bg-sun"
        icon="/png/footer/Element Tiere hell orange.png"
        logo="/png/footer/Logo_Hexenhaus.png"
      >
        {(isLoading || loadingImgs) && <Loading />}
        {(error || errImgs) && <NotFound />}
        {data && imgs && (
          <>
            <BaseHeroSmall
              headline={"Hexenhaus"}
              sectioncolor={"bg-sun"}
              icons="png/weiss/Elemente_Tiere Hexenhaus.png"
              padding="-bottom-[1.4rem]"
            />
            <BaseViewPort>
              <BaseTextSection
                headline={data?.headline1}
                color="text-sun"
                content={data?.absatz1}
              />
            </BaseViewPort>
            <BaseSliderImg imgs={imgs} />
            <BaseViewPort>
              <p className="w-56 py-1 px-4 self-center sm:self-end text-xl font-semibold text-base-100 bg-sun ">
                Kontakt
              </p>
              <div
                style={{
                  backgroundImage: `url(${
                    API.img + data?.contact_card?.picture?.data?.attributes?.url
                  })`,
                }}
                className="h-64 w-56 bg-cover bg-center self-center sm:self-end mt-4 flex justify-end items-end"
              >
                <p className="w-full bg-black/20 text-base-100 font-medium p-1 h-fit">
                  {data?.contact_card?.name}
                </p>
              </div>
              <p className="w-56 self-center sm:self-end pt-2">
                <span className="font-medium pr-2">Mail:</span>
                <a
                  className="underline text-blue"
                  href={`mailto:` + data?.contact_card?.mail}
                >
                  {data?.contact_card?.mail}
                </a>
              </p>
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
