import { ReactSVG } from "react-svg";

type FleckRightImgType = {
    paddingTop: string;
}

export default function FleckRightImg({ paddingTop } : FleckRightImgType ) {
    return (
        <div className="hidden sm:block w-full relative">
            <ReactSVG src="/svg/FleckRight.svg" className={`h-80 w-80 absolute right-0 ${paddingTop}`}/>   
        </div>
    )
}
