import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

export type BaseLayoutType = {
    title: string;
    children: ReactNode;
    url?: string; 
    description?: string; 
    keywords?: string;
}

export default function BaseLayout({title, url, description, keywords, children}: BaseLayoutType) {
  return (
    <section className='grow w-full flex flex-col items-center justify-start'>
        <Helmet>
            <title>{title}</title>
            { url && <link rel='canonical' href={url} /> }
            { description && <meta name='description' content={description} /> }
            { keywords && <meta name='keywords' content={keywords} /> }
        </Helmet>
        {children}
    </section>
  )
}
