import axios from "axios";
import { API } from "../../../utils/constants";

export const fetchImgs = async () => {
  const response = await axios.get(API.common + "/hexenhaus-page?populate=*");
  return response.data.data.attributes.slider.data;
};

export const fetchData = async () => {
  const response = await axios.get(
    API.common + "/hexenhaus-page?populate=seo&populate=contact_card.picture"
  );
  return response.data.data.attributes;
};
