
import ReactMarkdown from 'react-markdown';
import { customComponents } from '../common/markdown/ComponentStyle';
import gfm from 'remark-gfm';
import '../../style/markdown.css'

type TextSectionType = {
  color?: string;
  content: string;
  headline?: string;
  padding?: string;
  headline_center?: boolean;
}

export default function BaseHistoryText( props : TextSectionType) {
  
  return (
    <div className={`flex flex-col ${props.padding ? props.padding : 'py-8 sm:py-16'}`}>
        <h3 className={`line-clamp-2 text-2xl font-semibold ${props.color} pb-3 ${props.headline_center && 'w-full text-center'}`}>{props.headline ?? ''}</h3>
        <p className='gap-8 columns-1 sm:columns-2 md:columns-3 text-base-200'>
          <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>{props.content}</ReactMarkdown>
        </p>
    </div>
  )
}
