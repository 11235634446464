import { useQuery } from "react-query";
import BaseLayout from "../../components/layout/BaseLayout";
import BasePage from "../../components/layout/BasePage";
import { fetchData } from "./api/requeest";
import BaseTextSection from "../../components/layout/BaseTextSection";
import BaseViewPort from "../../components/layout/BaseViewPort";
import ReactMarkdown from "react-markdown";
import { customComponents } from "../../components/common/markdown/ComponentStyle";
import GoogleMaps from "./components/GoogleMaps";
import ErrorPage from "../404/ErrorPage";
import Loading from "../../components/common/Loading";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import gfm from 'remark-gfm';
import '../../style/markdown.css'

export default function Kontakt() {
  const { data, error, isLoading } = useQuery("dataContact", fetchData);
  return (
      <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
            <BasePage color={"bg-blue"} icon='/png/Elemente_Tiere blau.png' logo='/png/footer/Logo_Startseite.png'>
            <BaseHeroSmall headline={'Kontakt & Anfahrt'} sectioncolor={'bg-blue'} />
            { error && <ErrorPage /> }
            { isLoading && <Loading /> }
            { data && <>
                <BaseViewPort>
                    <div id='_'/>
                    <BaseTextSection padding="pt-24" headline="Anschriften" color="text-blue" content={""} />
                    <div className="w-full flex flex-col sm:flex-row">
                        <div className="flexflex-col w-full sm:w-1/2 sm:px-8 pb-4">
                            <h4 className="font-bold">{data.headline1}</h4>
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                                {data.content1}
                            </ReactMarkdown>
                        </div>
                        <div className=" flex flex-col w-full sm:w-1/2 sm:px-8">
                            <h4 className="font-bold">{data.headline2}</h4>
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                                {data.content2}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <div className="w-full flex flex-col sm:flex-row pt-4 border-t">
                        <div className="flexflex-col w-full sm:w-1/2 sm:px-8">
                            <h4 className="font-bold">{data.headline3}</h4>
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                                {data.content3}
                            </ReactMarkdown>
                        </div>
                        <div className=" flex flex-col w-full sm:w-1/2 sm:px-8">
                            <h4 className="font-semibold">{data.headline4}</h4>
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                                {data.content4}
                            </ReactMarkdown>
                        </div>
                    </div>
                <BaseTextSection padding="pt-24" headline="Anfahrt" color="text-blue" content={""} />
                <GoogleMaps />
                </BaseViewPort>
            </> }
            </BasePage>
        </BaseLayout>
    )
}
