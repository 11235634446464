import axios from "axios"
import { API } from "../../../utils/constants"

export const fetchData = async () => {
    const response = await axios.get(API.common + '/home-page?populate=*')
    return response.data.data.attributes
}

export const fetchNews = async () => {
    const response = await axios.get(API.common + '/home-page?populate=news.img&&populate=news.file')
    return response.data.data.attributes
}

export const fetchCards = async () => {
    const response = await axios.get(API.common + '/home-page?populate=cards.file')
    return response.data.data.attributes
}

export const fetchLogo = async () => {
    const response = await axios.get(API.common + '/logo?populate=*')
    return response?.data?.data?.attributes?.logo?.data?.attributes
}

export const fetchHistory= async () => {
    const response = await axios.get(API.common + '/geschichte-elementes')
    return response?.data?.data?.sort((a: { attributes: { position: number } }, b: { attributes: { position: number } }) => {
        return a.attributes.position > b.attributes.position ? 1 : -1
      }).slice(0, 12)
}