
import BaseHero from '../../components/layout/BaseHero'
import BaseTextSection from '../../components/layout/BaseTextSection'
import PicSection from './components/PicSection'
import BaseHistorySection from '../../components/layout/BaseHistorySection'
import SignInSection from './components/SignInSection'
import BasePage from '../../components/layout/BasePage'
import BaseViewPort from '../../components/layout/BaseViewPort'
import BaseSliderImg from '../../components/layout/BaseSliderImg'
import AnimalLineImg from '../../components/common/AnimalLineImg'
import { useQuery } from 'react-query'
import Loading from '../../components/common/Loading'
import NotFound from '../../components/common/NotFound'
import { fetchCards, fetchData, fetchHistory, fetchLogo, fetchNews } from './api/request'
import BaseLayout from '../../components/layout/BaseLayout'
import { fetchBlogData } from '../Blog/api/request'

export default function Homepage() {
  
  const { data, error: homeError, isLoading: homeLoading } = useQuery('homeData', fetchData);
  const { data: news, error: newsError, isLoading: newsLoading } = useQuery('newsData', fetchNews);
  const { data: cards, error: cardsError, isLoading: cardsLoading } = useQuery('cardsData', fetchCards);
  const { data: logo, error: logoError, isLoading: logoLoading } = useQuery('logoData', fetchLogo);
  const { data: history, error: historyError, isLoading: historyLoading } = useQuery('historyData', fetchHistory);
  const { data: blogData, error: blogError, isLoading: BlogIsLoading } = useQuery("dataBlog", fetchBlogData);
  
  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color='bg-blue' icon='/png/Elemente_Tiere blau.png' logo='/png/footer/Logo_Startseite.png'>
        { (homeLoading || newsLoading || cardsLoading || logoLoading || historyLoading) && <Loading /> }
        { (homeError || newsError || cardsError || logoError || historyError) && <NotFound />}
        { (data && cards && logo && news && history) && 
        <>
        <BaseHero logo={logo} img={data?.hero} headline1={data?.headline1} headline2={data?.headline2} text={data?.text1} />
        <BaseViewPort>
          <BaseTextSection color='text-blue' content={data?.absatz1} headline={data?.headline3} padding='pt-16 pb-8 sm:py-16'/>
          <BaseTextSection color='text-blue' headline={data?.headline4} content='' padding='p-0' />
          <PicSection blog={blogData} item1={news?.news[0]} item2={news?.news[1]} item3={news?.news[2]}/>
        </BaseViewPort>
        <AnimalLineImg />
        <BaseViewPort>
          <BaseTextSection color='text-blue' headline='Kurzhistorie' content={''} padding='pb-0 sm:pt-8'/>
          <BaseHistorySection padding items={history} />
        </BaseViewPort>
        <BaseSliderImg imgs={data?.slider?.data}/>
        <SignInSection card1={cards?.cards[0]} card2={cards?.cards[1]} card3={cards?.cards[2]}/>
        </>
        }
      </BasePage>
    </BaseLayout>
  )
}
