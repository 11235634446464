
type AnimalLineImgType = {
    right?: boolean;
}

export default function AnimalLineImg({ right }: AnimalLineImgType) {
    return (
        <div className="w-full relative sm:h-28 md:h-40 flex items-center overflow-hidden">
            <img src="/png/AnimalsLine.png" alt="Tier Linie" className={`h-full md:h-40 w-fit max-w-4xl absolute ${right ? '-right-8' : '-left-8'}`}/>
        </div>
    )
}
