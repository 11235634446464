
import React from 'react'

type BaseHeroSmallType = {
    headline: string;
    sectioncolor: string;
    icons?: string;
    padding?: string;
}

export default function BaseHeroSmall( props: BaseHeroSmallType ) {
    return (
        <div id='_' className={`w-full flex justify-center relative pt-12 lg:pt-28 pb-12 ${props.sectioncolor} overflow-hidden`}>
            <div className=' max-w-screen-xl w-full px-4 sm:px-8 md:px-16'>
            <h1 className='text-4xl md:text-5xl font-headline uppercase text-base-100'>
                {props.headline}
            </h1>
            </div>
            { props.icons && <img alt='tiere weiss' src={props.icons} className={`h-[4.5rem] sm:h-20 md:h-24 absolute right-8 md:right-32 lg:right-64 ${props.padding ? props.padding : '-bottom-6 '}`}/> }
        </div>   
    )
}
