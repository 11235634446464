import BasePage from "../../../components/layout/BasePage";
import BaseHeroSmall from "../../../components/layout/BaseHeroSmall";
import BaseTextSection from "../../../components/layout/BaseTextSection";
import BaseViewPort from "../../../components/layout/BaseViewPort";
import { fetchGebote } from "../api/request";
import { useQuery } from "react-query";
import Loading from "../../../components/common/Loading";
import NotFound from "../../../components/common/NotFound";
import BaseLayout from "../../../components/layout/BaseLayout";

export default function GebotePage() {
  const { data, error, isLoading } = useQuery("dataGebote", fetchGebote);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-yellow" icon="/png/footer/Element Tiere gelb.png" logo="/png/footer/Logo_über die NaSch.png">
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Über die NaSch"}
              sectioncolor={"bg-yellow"}
              icons="/png/weiss/Elemente_Tiere über die NaSch.png"
            />
            <BaseViewPort>
              <h3 className={`text-2xl font-medium text-yellow pl-1 pb-3 pt-16`}>
                {data?.headline1}
              </h3>
              <p className="font-bold w-1/2 pr-4 pb-0">{data?.absatz0}</p>
              <BaseTextSection padding="pb-16" content={data?.absatz1} />
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}
