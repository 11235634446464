import BaseHeroSmall from '../../components/layout/BaseHeroSmall'
import BaseTextSection from '../../components/layout/BaseTextSection'
import BasePage from '../../components/layout/BasePage'
import ForwardingSection from './components/ForwardingSection'
import BaseViewPort from '../../components/layout/BaseViewPort'
import BaseSliderImg from '../../components/layout/BaseSliderImg'
import AnimalLineImg from '../../components/common/AnimalLineImg'
import FleckRightImg from '../../components/common/FleckRightImg'
import { useQuery } from 'react-query'
import { fetchData } from './api/request'
import Loading from '../../components/common/Loading'
import NotFound from '../../components/common/NotFound'
import BaseLayout from '../../components/layout/BaseLayout'

export default function AboutPage() {

    const { data, error, isLoading } = useQuery('aboutData', fetchData);

    return (
        <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
            <BasePage color='bg-yellow' icon='png/footer/Element Tiere gelb.png' logo='png/footer/Logo_über die NaSch.png'>
                { isLoading && <Loading /> }
                { error && <NotFound /> }
                { data && <>
                    <BaseHeroSmall sectioncolor='bg-yellow' headline={'Über die NaSch'} icons='png/weiss/Elemente_Tiere über die NaSch.png' />
                    <BaseViewPort>
                        <BaseTextSection color='text-yellow' headline={data?.headline1} content={data?.absatz1} />
                        <ForwardingSection cards={data?.cards}/>
                    </BaseViewPort>
                    <FleckRightImg paddingTop='-top-96' />
                    <AnimalLineImg />
                    <BaseSliderImg imgs={data?.slider?.data}/>
                </> }
            </BasePage>
        </BaseLayout>
    )
}

