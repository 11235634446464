import ReactMarkdown from 'react-markdown';
import { motion } from "framer-motion";
import { customComponents } from '../../../components/common/markdown/ComponentStyle';
import gfm from 'remark-gfm';
import '../../../style/markdown.css'

type ConsultingBigAccordionType = {
    bool: boolean;
    func: () => void;
    headline: string;
    text1: string;
    text2: string;
}


export default function ConsultingBigAccordion ( props : ConsultingBigAccordionType ) {

    return(
        <div className='w-full flex flex-col overflow-hidden static overflow-y-hidden'>
            <div className='h-4 bg-base-100'/>
            <div onClick={props.func}  className={`cursor-pointer w-full flex flex-row justify-between items-center uppercase px-2 h-fit sm:h-10 font-medium bg-green text-base-100`}>
                <p className='pt-2 text-lg normal-case'>
                {props.headline}
                </p>
                <motion.svg 
                    initial={{
                        rotate: 0
                    }}
                    animate={{
                        rotate: props.bool ? 180 : 0
                    }}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 pt-1 z-50 cursor-pointer"><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></motion.svg>
            </div>

            <motion.div
                animate={{ 
                    height: props.bool ? '0%' : ''
                }}
                transition={{
                    duration: 0.5,
                    type: 'ease'
                }}
                className={`h-0 border-2 border-green`}
                >
                    <div className='flex flex-col w-full'>
                        <div className={`py-8 px-4 w-full columns-1 sm:columns-2 gap-8`}> 
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                            {props.text1}
                            </ReactMarkdown>
                        </div>
                        <div className='h-2 w-full bg-green'/>
                        <div className={`pt-8 pb-24 sm:pb-16 px-4 w-full columns-1 sm:columns-2 gap-8 relative`}> 
                            <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                            {props.text2}
                            </ReactMarkdown>
                            <img src={'png/LogoSchulsozialarbeit.png'} alt='Logo Sozialarbeit' className='absolute bottom-4 right-4 h-16'/>
                        </div>
                    </div>
            </motion.div>
        </div>
    )
}