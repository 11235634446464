
import React from 'react'
import { handleDownload } from '../../../services/download';
import { API } from '../../../utils/constants';

type DownloadArchivType = {
    button: {
        jahr: string;
        pdf: {
            data: {
                attributes: {
                    name: string;
                    url: string;
                }
            }
        }
    }[]
}

export default function DownloadArchiv({ button }: DownloadArchivType) {
    return (
        <div className='w-full flex flex-wrap justify-center gap-2'>
            {button && button.map(item => (
                <button key={item.jahr} onClick={() => handleDownload(API.img + item?.pdf?.data?.attributes?.url, item?.pdf?.data?.attributes?.name ?? 'nasch.pdf')} className='py-4 px-4 bg-gray-500 text-base-100'>
                    {item.jahr}
                </button>
            ))}
        </div>
    )
}
