import axios from "axios";
import { API } from "../../../utils/constants";

export const fetchData = async () => {
  const response = await axios.get(API.common + "/ueberuns-page?populate=*");
  return response.data.data.attributes;
};

export const fetchGebote = async () => {
  const response = await axios.get(API.common + "/gebote-page?populate=*");
  return response.data.data.attributes;
};

export const fetchLeitbild = async () => {
  const response = await axios.get(API.common + "/leitbild-page?populate=*");
  return response.data.data.attributes;
};

export const fetchPositions = async () => {
  const response = await axios.get(API.common + "/arbeiten-page?populate=*");
  return response.data.data.attributes;
};

export const fetchTraditionen = async () => {
  const response = await axios.get(API.common + "/traditionen-page?populate=*");
  return response.data.data.attributes;
};

export const fetchHistory = async () => {
  const response = await axios.get(API.common + "/geschichte-page?populate=*");
  return response?.data?.data?.attributes;
};
