
import React, { ReactNode } from 'react'
import Footer from '../common/Footer';

type BasePageType = {
    color: string;
    icon?: string;
    logo?: string;
    children: ReactNode;
}

export default function BasePage({ color, icon, children, logo }: BasePageType) {
    return (
        <section className='w-full grow flex flex-col items-center overflow-y-hidden'>
            {children}
            <Footer logo={logo} color={color} icon={icon}/>
        </section>   
    )
}
