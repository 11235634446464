import React, { ReactNode, createContext, useState } from 'react'

type DrawerContextProps = {
    open: boolean;
    cssHidden: boolean;
    trigger: () => void
}

export const DrawerContext = createContext<DrawerContextProps | undefined>(undefined)

type DrawerContextProviderProps = {
    children: ReactNode
}

export const DrawerContextProvider: React.FC<DrawerContextProviderProps> = ({ children } : DrawerContextProviderProps ) => {
    
    const [open, setOpen] = useState(false)
    const [cssHidden, setCssHidden] = useState(true)
    const trigger = () => {
        console.log('click')
        if (open) {
            setOpen(!open)
            setTimeout(() => {
                setCssHidden(!cssHidden)
            }, 150)
        }
        else {
            setCssHidden(!cssHidden)
            setOpen(!open)
        }
    }

    return(
        <DrawerContext.Provider  value={{ open, cssHidden, trigger }}>
            {children}
        </DrawerContext.Provider>
    )
}
