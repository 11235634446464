import React from "react";
import { API } from "../../../utils/constants";

type ContactSocialWorkerType = {
  item1: {
    name: string;
    number: string;
    mail: string;
    handy_number: string;
  };
  item2: {
    name: string;
    number: string;
    mail: string;
    handy_number: string;
  };
  url1: string;
  url2: string;
};

export default function ContactSocialWorker({
  item1,
  url1,
  item2,
  url2,
}: ContactSocialWorkerType) {
  return (
    <div className="flex flex-col sm:flex-row pt-16 gap-8 justify-end items-center sm:items-start py-8 sm:py-16">
      <div className="flex flex-col w-fit">
        <div
          style={{ backgroundImage: `url(${API.img + url1})` }}
          className="h-64 w-56 bg-cover bg-center flex justify-end items-end"
        >
          <p className="w-full bg-black/30 text-base-100 font-medium p-1 h-fit">
            {item1?.name}
          </p>
        </div>
        {/* <img src={API.img + url} alt='contact img' className='w-56 h-64'/> */}
        <div className="flex flex-col w-56 pt-2">
          <p>
            <span className="font-semibold pr-2">Telefon:</span>
            {item1?.number}
          </p>
          <p>
            <span className="font-semibold pr-2">Handy-Nr.:</span>
            {item1?.handy_number}
          </p>
          <p>
            <span className="font-semibold pr-2">Mail:</span>
            <a className="underline text-blue" href={"mailto:" + item1?.mail}>
              {item1?.mail}
            </a>
          </p>
        </div>
      </div>
      <div className="flex flex-col w-fit">
        <div
          style={{ backgroundImage: `url(${API.img + url2})` }}
          className="h-64 w-56 bg-cover bg-center flex justify-end items-end"
        >
          <p className="w-full bg-black/30 text-base-100 font-medium p-1 h-fit">
            {item2?.name}
          </p>
        </div>
        {/* <img src={API.img + url} alt='contact img' className='w-56 h-64'/> */}
        <div className="flex flex-col w-56 pt-2">
          <p>
            <span className="font-semibold pr-2">Telefon:</span>
            {item2?.number}
          </p>
          <p>
            <span className="font-semibold pr-2">Handy-Nr.:</span>
            {item2?.handy_number}
          </p>
          <p>
            <span className="font-semibold pr-2">Mail:</span>
            <a className="underline text-blue" href={"mailto:" + item2?.mail}>
              {item2?.mail}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
