import { HashLink } from 'react-router-hash-link'
import { DrawerContext } from './DrawerContext';
import { useContext } from 'react'
import { motion } from 'framer-motion';

export default function Header() {

    const open = useContext(DrawerContext)
    const trigger = useContext(DrawerContext)
    const cssHidden = useContext(DrawerContext)

    return(
        <>
        {/* Header in Desktop */}
            <div className="bg-base-100 h-14 w-full hidden 1.5xl:flex flex-row items-center fixed top-0 overflow-hidden z-[9999]">
                <HashLink to={"/#_"} className="hover:bg-black/10 h-full w-fit px-4 flex items-center border-r border-black/50">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>
                </HashLink>
                <NavBarElement link='/überuns#_' title='über die Nasch' icon='/png/CircleSchildkröte.png'/>      
                <NavBarElement link='/beratung#_' title='Beratung' icon='/png/CircleFrosch.png'/>      
                <NavBarElement link='/schule#_' title='Schule' icon='/png/CircleFisch.png'/>      
                <NavBarElement link='/kindergarten#_' title='Kindergarten' icon='/png/CircleHase.png'/>      
                <NavBarElement link='/hort#_' title='Hort' icon='/png/CircleElefant.png'/>      
                <NavBarElement link='/elternrat#_' title='Elternräte' icon='/png/CircleVogel.png'/>      
                <NavBarElement link='/verein#_' title='Verein' icon='/png/CircleDino2.png'/>      
                <NavBarElement link='/hexenhaus#_' title='Hexenhaus' icon='/png/CircleSchaf.png'/>      
            </div>
        {/* Button for Drawer */}
            <div className="1.5xl:hidden fixed top-8 right-8 z-50 cursor-pointer" onClick={trigger?.trigger}>
                <div className="border-2 w-12 h-12 flex items-center justify-center rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8 text-base-100"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>
                </div>
            </div>
                    
        {/* Drawer in Respo itself */}
        <div className={`fixed flex-row h-screen w-screen overflow-hidden z-[1000] ${cssHidden?.cssHidden ? "hidden" : "flex"}`}>
            <motion.div
                        initial={{
                            x: "-100%",
                        }}
                        animate={{
                            x: open?.open ? "0%" : "-100%",
                        }}
                        transition={{
                            duration: 0.25,
                            type: "ease",
                        }}
                        className="absolute flex flex-col items-center w-80 border-primary text-base-content h-full bg-base-100 overflow-y-scroll pb-28"        
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-4 right-4" onClick={trigger?.trigger}><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
                        <div className='h-12'/>
                        <HashLink to='/#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-blue mt-3'>Startseite</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/überuns#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-yellow'>Über die NaSch</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/beratung#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-green'>Beratung</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/schule#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-cyan'>Schule</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/kindergarten#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-purple'>Kiga</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/hort#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-pink'>Hort</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/elternrat#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-orange'>Elternrat</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/verein#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-sky'>Verein</HashLink>
                        <div className='my-2 w-[90%] border'/>
                        <HashLink to='/hexenhaus#_' onClick={trigger?.trigger} className='w-full pt-4 py-3 pl-4 border-l-4 border-sun'>Hexenhaus</HashLink>
                    </motion.div>
                    <motion.div
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: open?.open ? 1 : 0,
                        }}
                        transition={{
                            duration: 0.05,
                            type: "ease",
                        }}
                        className="h-full w-screen bg-base-200/60 cursor-pointer"
                        onClick={trigger?.trigger}
                    />
            </div>
        </>
    )
}

type NavBarElementType = {
    title: string;
    link: string;
    icon?: string;
}

function NavBarElement ( props: NavBarElementType ) {
    return (
        <HashLink to={props.link} className="hover:bg-black/10 h-full 2lg:px-4 flex items-center border-r border-black/50  w-fit 2lg:min-w-[8rem] 1.5xl:pl-1 1.5xl:pr-3 2xl:pl-4  1.5xl:w-full 1.5xl:grow">
        {/* <HashLink to={props.link} className="hover:bg-black/10 h-full pl-1 pr-3 2lg:px-4 flex items-center border-r border-black/50 w-fit 2lg:min-w-[8rem]  1.5xl:min-w-[10rem] 2xl:min-w-[12rem]"> */}
            <img src={props.icon} alt={props.icon} className='w-12 h-12 scale-110 1.5xl:scale-125' />
            <p className=' pl-1 1.5xl:pl-2 tracking-tight font-medium'>
                {props.title}
            </p>
        </HashLink>            
    )
}