import ReactMarkdown from 'react-markdown';
import { motion } from "framer-motion";
import { useMemo } from 'react';
import { handleDownload } from '../../services/download';
import { API } from '../../utils/constants';
import { customComponents } from '../common/markdown/ComponentStyle';
import '../../style/markdown.css'
import gfm from 'remark-gfm';

type SimpleAccordType = {
    bool: boolean;
    button?: boolean;
    columns?: boolean;
    func: () => void;
    headline: string;
    text: string;
    text2?: string;
    picture?: string;
    color: string;
    file?: {
        data: {
            attributes: {
                name: string;
                url: string;
            }
        }
    };
}


export default function BaseAccordion ( props : SimpleAccordType ) {

    const borderColor = useMemo(() => 'border-'+props.color, [props.color]) 
    const bgColor = 'bg-' + props.color 
    return(
        <div className='w-full flex flex-col overflow-hidden static overflow-y-hidden'>
            <div className='h-4 bg-base-100'/>
            <div onClick={props.func} className={`w-full flex flex-row justify-between items-center uppercase px-2 h-fit sm:h-10 font-medium cursor-pointer ${bgColor} text-base-100`}>
                <p className='pt-2 text-lg normal-case'>
                {props.headline}
                </p>
                <motion.svg 
                    initial={{
                        rotate: 0
                    }}
                    animate={{
                        rotate: props.bool ? 180 : 0
                    }}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 pt-1 z-50"><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></motion.svg>
            </div>

            <motion.div
                animate={{ 
                    height: props.bool ? '0%' : ''
                }}
                transition={{
                    duration: 0.5,
                    type: 'ease'
                }}
                className={`h-0 border-2 ${borderColor}`}
                >
                    <div className={`py-8 px-4 w-full ${ props.columns && 'columns-1 sm:columns-2 gap-8'}`}> 
                    <p>
                        <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                            {props.text}
                        </ReactMarkdown >
                    </p>
                    { props.picture && <div className='w-full flex justify-start pl-2'><img alt='Elternrat' src={props.picture} className='h-80 max-w-[26rem] w-fit mt-8 self-center'/></div> }
                    {props.button && <button onClick={() => handleDownload(API.img + props?.file?.data?.attributes?.url, props?.file?.data?.attributes?.name ?? 'nasch.pdf')} className={`px-4 py-2 text-base-100 text-sm my-4 ${bgColor}`}>Anmeldebogen</button> }
                    {
                        props.text2 && 
                    <ReactMarkdown components={customComponents} remarkPlugins={[gfm]} className='markdown'>
                        {props.text2}
                        </ReactMarkdown>
                    }
                    </div>
            </motion.div>
        </div>
    )
}