import BasePage from "../../../components/layout/BasePage";
import BaseHeroSmall from "../../../components/layout/BaseHeroSmall";
import { useQuery } from "react-query";
import Loading from "../../../components/common/Loading";
import NotFound from "../../../components/common/NotFound";
import { fetchHistory } from "../api/request";
import BaseHistoryText from "../../../components/layout/BaseHistoryText";
import BaseLayout from "../../../components/layout/BaseLayout";

export default function HistoryPage() {
  const { data, error, isLoading } = useQuery("dataHistory", fetchHistory);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-yellow" icon="/png/footer/Element Tiere gelb.png" logo="/png/footer/Logo_über die NaSch.png">
        <BaseHeroSmall
          headline={"Unsere Geschichte"}
          sectioncolor={"bg-yellow"}
          icons="/png/weiss/Elemente_Tiere über die NaSch.png"
        />
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <div className="flex flex-col w-full max-w-screen-xl px-4 xl:px-0">
            <BaseHistoryText content={data?.text} color="text-yellow" padding="pt-16" headline="Unsere Geschichte"/>
          </div>
        )}
      </BasePage>
    </BaseLayout>
  );
}
