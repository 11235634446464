
import { HashLink } from 'react-router-hash-link';
import { API } from '../../utils/constants';
import ReactMarkdown from 'react-markdown';
import { customComponents } from '../common/markdown/ComponentStyle';

type BaseHeroType = {
    headline1?: string;
    headline2?: string;
    text?: string;
    img?: {
        data: {
            attributes: {
                url: string;
            }
        }
    }
    logo?: {
        name: string;
        url: string;
    }
}

export default function BaseHero( props : BaseHeroType ) {

    return (
        <div id='_' className='w-full h-[32rem] relative flex flex-col items-center justify-center mb-32 bg-cover bg-center' style={{ backgroundImage: `url(${API.img + props?.img?.data?.attributes?.url})`}}>
            { props?.logo && <img src={API.img + props?.logo?.url} alt={props?.logo?.name} className='absolute hidden md:block w-80 h-fit max-h-[20rem] top-16 left-32'/> } 
            <div className='h-64 w-full max-w-screen-lg absolute -bottom-40 md:-bottom-32 flex flex-col sm:flex-row justify-center items-center'>
                <div className='w-full sm:w-1/2 h-full md:h-fit bg-slate-200 py-4 sm:py-10 flex px-8 md:px-0 items-center'>
                    <h3 className='text-slate-500 text-2xl md:text-3xl font-semibold md:px-16'>{props.headline1}</h3>
                </div>
                <div className='w-full sm:w-1/2 h-full bg-blue py-4 sm:py-0 px-8 md:px-20 text-base-100 flex flex-col justify-center'>
                    <h3 className='uppercase text-2xl md:text-3xl pb-2'>{props.headline2}</h3>
                    <ReactMarkdown components={customComponents}>
                        {props?.text ?? ''}
                    </ReactMarkdown>                    
                    <span className='underline font-semibold'><HashLink to='/überuns/stellen'>mehr dazu</HashLink></span>
                </div>
                <div className='h-14 w-16 bg-base-100 absolute hidden md:flex justify-center'>
                    <img src='png/Elemente_Fuchs_blau.png' alt='blauer Fuchs' className='h-full w-fit'/>
                </div>
            </div>
        </div>
    )
}
