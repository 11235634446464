
import axios from "axios"
import { API } from "../../../utils/constants"

export const fetchData = async () => {
    const response = await axios.get(API.common + '/verein-page?populate=*')
    return response.data.data.attributes
}

export const fetchKonto = async () => {
    const response = await axios.get(API.common + '/vereins-konto')
    return response.data.data.attributes
}
