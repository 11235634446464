import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DrawerContextProvider } from './components/common/DrawerContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <DrawerContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DrawerContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
