import { useState } from "react";
import BasePage from "../../components/layout/BasePage";
import BaseHeroSmall from "../../components/layout/BaseHeroSmall";
import { HashLink } from "react-router-hash-link";
import BaseViewPort from "../../components/layout/BaseViewPort";
import BaseTextSection from "../../components/layout/BaseTextSection";
import BaseAccordion from "../../components/layout/BaseAccordion";
import { API } from "../../utils/constants";
import { fetchData } from "./api/request";
import { useQuery } from "react-query";
import Loading from "../../components/common/Loading";
import NotFound from "../../components/common/NotFound";
import BaseLayout from "../../components/layout/BaseLayout";

export default function ParentsPage() {
  const [acc1, setAcc1] = useState(false);
  const [acc2, setAcc2] = useState(false);
  const [acc3, setAcc3] = useState(false);

  const { data, error, isLoading } = useQuery("dataParents", fetchData);

  return (
    <BaseLayout title={data?.seo?.metaTitle ?? ''} description={data?.seo?.metaDescription ?? ''} keywords={data?.seo?.keywords ?? ''} url={data?.seo?.canonicalURL ?? null}>
      <BasePage color="bg-orange" icon="/png/footer/Element Tiere orange.png" logo="/png/footer/Logo_Elternräte.png">
        {isLoading && <Loading />}
        {error && <NotFound />}
        {data && (
          <>
            <BaseHeroSmall
              headline={"Elternräte"}
              sectioncolor={"bg-orange"}
              icons="png/weiss/Elemente_Tiere Elternrat.png"
            />
            <div className="flex flex-col w-full max-w-screen-xl p-4 sm:py-16 sm:px-16">
              <h3 className="text-2xl font-semibold text-orange">
                {data?.headline1}
              </h3>
              <p className="w-full sm:w-96 text-base-200 pt-2">{data?.absatz1}</p>
            </div>
            <div className="w-full h-[32rem] relative hidden sm:block">
              <div
                className="absolute right-0 h-full w-2/3 z-0 bg-center bg-cover"
                style={{
                  backgroundImage: `url(${
                    API.img + data?.picture?.data?.attributes?.url
                  })`,
                }}
              >
                <div className="absolute top-24 -left-64 flex flex-col w-full max-w-lg z-10">
                  <LinkBox
                    link={"#" + data?.elternrat1}
                    text={data?.elternrat1}
                  />
                  <LinkBox
                    link={"#" + data?.elternrat2}
                    text={data?.elternrat2}
                  />
                  <LinkBox
                    link={"#" + data?.elternrat3}
                    text={data?.elternrat3}
                  />
                </div>
              </div>
            </div>
            <BaseViewPort>
              <div id={data?.elternrat1} />
              <BaseTextSection
                headline={data?.elternrat1}
                color="text-orange"
                content={data?.elternrat1_text}
                padding="pt-4 sm:pt-16 pb-8"
              />
              <BaseAccordion
                bool={acc1}
                func={() => setAcc1(!acc1)}
                headline={data?.dropdown1_headline}
                text={data?.elternrat1_dropdown}
                picture={API.img + data?.bild_dropdown1?.data?.attributes?.url}
                columns
                color={"orange"}
              />
              <div id={data?.elternrat2} />
              <BaseTextSection
                headline={data?.elternrat2}
                color="text-orange"
                content={data?.elternrat2_text}
                padding="pt-8 sm:pt-16 pb-8"
              />
              <BaseAccordion
                bool={acc2}
                func={() => setAcc2(!acc2)}
                headline={data?.dropdown2_headline}
                text={data?.elternrat2_dropdown}
                columns
                color={"orange"}
              />
              <div id={data?.elternrat3} />
              <BaseTextSection
                headline={data?.elternrat3}
                color="text-orange"
                content={data?.elternrat3_text}
                padding="pt-8 sm:pt-16 pb-8"
              />
              <BaseAccordion
                bool={acc3}
                func={() => setAcc3(!acc3)}
                headline={data?.dropdown3_headline}
                text={data?.elternrat3_dropdown}
                columns
                color={"orange"}
              />
            </BaseViewPort>
          </>
        )}
      </BasePage>
    </BaseLayout>
  );
}

type LinkBoxType = {
  text: string;
  link: string;
};

function LinkBox({ text, link }: LinkBoxType) {
  return (
    <HashLink
      smoothScroll={true}
      to={link}
      className="cursor-pointer py-2 w-full mb-4 self-center flex items-center px-10 justify-between bg-orange text-base-100"
    >
      <p className="text-2xl font-medium">{text}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-8 h-8 z-50 cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </HashLink>
  );
}
