import React, { useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { API } from '../../utils/constants';

type BaseSliderImgType = {
    imgs?: {
        attributes: {
            name: string;
            url: string;
        }
    }[]
}

export default function BaseSliderImg({ imgs }: BaseSliderImgType) {

    const splideRef = useRef<any>(null);

    const handlePrev = () => {
    if (splideRef?.current) {
        splideRef?.current?.splide.go('-1'); // Go to the previous slide
    }
    };

    const handleNext = () => {
    if (splideRef?.current) {
        splideRef?.current?.splide.go('+1'); // Go to the next slide
    }
    };

    return (
        <div className='w-full h-[28rem] sm:h-full py-8 sm:py-16 flex flex-col items-center z-0 overflow-hidden'>
            <div className='w-full h-fit flex justify-center items-center relative' >
            <Splide options={{
                type: 'loop',
                perPage: 1,
                perMove:1,
                width: '100%', 
                height: 'object-fit',
                arrows: false,
                pagination: false, // menu points
            }}
            ref={splideRef}
            className=''
            >

            {imgs && imgs?.map((slide) => (
                <SplideSlide key={slide?.attributes?.name} className='w-full'>
                    <div className='w-screen h-96 sm:h-[40rem] bg-cover bg-center' style={{backgroundImage: `url(${API.img + slide?.attributes?.url})`}}>
                        {/* NOTE -> Test performance - maybe need to change backgroundImage to img with lazy loading */}
                    </div>
                </SplideSlide>
                ))}
            </Splide>

                <div className='absolute w-[70%] h-16 sm:bottom-8'>
                    <div className='rounded-sm border-2 border-base-100 left-0 absolute '>
                        <svg onClick={handlePrev} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-12 h-12 text-base-100"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
                    </div>
                    <div className='rounded-sm border-2 border-base-100 right-0 absolute '>
                        <svg onClick={handleNext} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="cursor-pointer w-12 h-12 text-base-100"><path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" /></svg>
                    </div>
                </div>
            </div>
        </div>   
    )
}
