import axios from "axios"
import { API } from "../../../utils/constants"

export const fetchData = async () => {
    const response = await axios.get(API.common + '/beratung-page?populate=*')
    return response.data.data.attributes
}

export const fetchContact1 = async () => {
    const response = await axios.get(API.common + '/beratung-page?populate=contact1.picture')
    return response.data.data.attributes.contact1.picture.data.attributes.url
}

export const fetchContact2 = async () => {
    const response = await axios.get(API.common + '/beratung-page?populate=contact2.picture')
    return response.data.data.attributes.contact2.picture.data.attributes.url
}

export const fetchContact3 = async () => {
    const response = await axios.get(API.common + '/beratung-page?populate=contact3.picture')
    return response.data.data.attributes.contact3.picture.data.attributes.url
}

export const fetchContact4 = async () => {
    const response = await axios.get(API.common + '/beratung-page?populate=contact4.picture')
    return response.data.data.attributes.contact4.picture.data.attributes.url
}