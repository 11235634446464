import React from 'react'
import { HashLink } from 'react-router-hash-link';

interface CardElements {
    headline?: string;
    text?: string;
    link?: string;
    hintergrund?: boolean;
}

type ForwardingSectionType = {
    cards: CardElements[]
}

export default function ForwardingSection({ cards }: ForwardingSectionType) {
    return (
        <div className='flex flex-wrap justify-center xl:justify-end gap-8 w-fit px-0 sm:px-16 sm:pb-16 z-10'>
            { cards && cards?.map(item => (
                <ForwardingBox key={item?.headline} filled={item?.hintergrund ?? false} link={item?.link} headline={item?.headline} content={item?.text}/>
            ))}
        </div>
    )
}

type ForwardingBoxType = {
    filled?: boolean;
    headline?: string;
    content?: string;
    link?: string;
}

function ForwardingBox ( props : ForwardingBoxType) {
    return(
        <div className={`w-full max-w-xs sm:w-80 px-8 py-4 text-base-200/60 font-medium flex flex-col justify-between ${props.filled ? 'bg-yellow' : 'border border-base-200'}`}>
            <h3 className='text-3xl'>{props.headline}</h3>
            <p className='pb-6 pt-2'>{props.content}</p>
            <HashLink to={props.link + '#_'} className={`border btn rounded-none btn-ghost w-fit ${props.filled ? 'border-base-100 border-2' : 'bg-yellow'}`}>Mehr dazu</HashLink>
        </div>
    )
}