import axios from "axios"

export async function handleDownload (pdfLink: string, name: string) {
    axios
        .get(
            pdfLink,
            {
                responseType: 'blob',
            }
        )
        .then((res) => {
            const pdfUrl = URL.createObjectURL(res.data)
            const link = document.createElement('a')
            link.href = pdfUrl
            link.download = name
            link.click()
        })
        .catch((err) => console.log(err))

}