
import React, { useMemo } from 'react'
import { HashLink } from 'react-router-hash-link';
import { API } from '../../../utils/constants';
import { BlogPostType } from '../../Blog/Blog';

interface PicSectionInterface {
  id?: number;
  headline: string;
  text?: string;
  content?: string;
  img?: {
    data: {
      attributes: {
        name: string;
        url: string;
      }
    }
  }
  file?: {
    data: {
      attributes: {
        name: string;
        url: string;
      }
    }
  }
  attributes?: {
    headline: string,
    content: string,
    Kurzbeschreibung?: string,
    cover?: {
      data: {
        attributes: {
          name: string;
          url: string;
        }
      } 
    }
  }
}

type PicSectionType = {
  item1?: PicSectionInterface;
  item2?: PicSectionInterface;
  item3?: PicSectionInterface;
  blog: BlogPostType[];
}

export default function PicSection({ item1, blog }: PicSectionType ) {

  return (
    <div className='flex flex-row flex-wrap gap-8 w-full justify-center xl:justify-between pb-16'>
      { item1 && <NewsElement item={item1} link="/überuns/stellen"/>}
      { blog && (
          item1 ? blog.slice(-2) : blog.slice(-3)
        )
        .reverse().map((item, index) => (
          <NewsElement item={item} key={index} link='/blog#post'/>
        ))}
    </div>
  )
}

type NewsElementType = {
  item: PicSectionInterface;
  link: string;
}

function NewsElement( { item, link } : NewsElementType) {

  const imglink = useMemo(() => {
    if (item?.img?.data?.attributes?.url) {
      return item?.img?.data?.attributes?.url
    } else if (item?.attributes?.cover?.data?.attributes?.url) {
      return item?.attributes?.cover?.data?.attributes?.url
    } else {
      return ''
    }
  },[item])

  return(
    <div className='grow w-full sm:min-w-[20rem] sm:max-w-[22rem] flex flex-col'>
      <div className='h-64 w-full bg-cover bg-center' style={{ backgroundImage: `url(${API.img + imglink})` }}/>
      <h4 className='font-semibold pt-4 text-lg sm:text-xl'>{item?.headline ? item?.headline : item?.attributes?.headline}</h4>
      <p className='pt-1 text-lg sm:text-xl leading-7 pb-4'>{ item?.text ? item?.text : item?.attributes?.Kurzbeschreibung ? item?.attributes?.Kurzbeschreibung : ''}</p>
      <HashLink to={ link === '/überuns/stellen' ? link : link + item?.id} className='border border-base-200 btn rounded-none btn-ghost w-fit'>Mehr dazu</HashLink>
    </div>
  )
}